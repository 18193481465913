<template>
  <div class="'container p-0 bg-white">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  setup() {},
});
</script>

<style lang="scss" scoped></style>
